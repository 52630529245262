import { useStaticQuery, graphql, Link } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import Button from "react-bootstrap/Button";
import ReviewSection from "../../components/review-section";
import TreatmentHero from "../../components/treatment-hero";
import TreatmentCard from "../../components/treatment-card";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import PhotoLeft from "../../components/photo-left";
import { GatsbyImage } from "gatsby-plugin-image";
import { checkPropertiesForNull } from "../../utils/objectHelper";
import { SafeHtmlParser } from "../../components/safe-html-parser";
import uuid4 from "uuid4";

const GeneralDenistryPage = () => {
  const data = useStaticQuery(graphql`
    query {
      reviews {
        reviewCount
      }
      schemaLogo: wpMediaItem(title: { eq: "Logo-footer" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: TRACED_SVG
            )
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "general-dentistry" }) {
        slug
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
        generalDentistryFieldGroups {
          generalDentistryIntroSection {
            heading
            subHeading
            title
            subtitle
            description
            cta {
              title
              target
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          generalDentistryReviewSection {
            heading
            subHeading
          }
          generalDentistryTextWithCtaSection {
            heading
            subHeading
            description
            cta {
              title
              target
              url
            }
            cta2 {
              title
              target
              url
            }
          }
          generalDentistryServiceSection {
            heading
            subHeading
            cta {
              target
              title
              url
            }
            items {
              heading
              price
              content
              image {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          }
          generalDentistryTwoColumnSection {
            heading
            content
            cta {
              title
              target
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          generalDentistrySmallTwoColumnSection {
            heading
            content
            cta {
              title
              target
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, generalDentistryFieldGroups, slug },
  } = data;

  const {
    generalDentistryIntroSection,
    generalDentistryReviewSection,
    generalDentistryTextWithCtaSection,
    generalDentistryServiceSection,
    generalDentistryTwoColumnSection,
    generalDentistrySmallTwoColumnSection,
  } = generalDentistryFieldGroups;

  const siteUrl = data.site.siteMetadata.siteUrl;
 
  const serviceSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "@id": `https://www.dentalhousepinner.co.uk/treatments/${slug}/#${slug}`,
    image: `https://www.dentalhousepinner.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
    mpn: `https://www.dentalhousepinner.co.uk/treatments/${slug}`,
    sku: `https://www.dentalhousepinner.co.uk/treatments/${slug}`,
    description: `${seoFieldGroups?.productSchema}`,
    logo: `https://www.dentalhousepinner.co.uk${data.schemaLogo?.localFile.publicURL}`,
    name: "Dental House Pinner",
    url: "https://www.dentalhousepinner.co.uk",
    brand: {
      "@type": "Organization",
      logo: `https://www.dentalhousepinner.co.uk${data.schemaLogo?.localFile.publicURL}`,
      image: `https://www.dentalhousepinner.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
      name: "Dental House Pinner",
      url: "https://www.dentalhousepinner.co.uk",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      reviewCount: `${data.reviews.reviewCount}`,
      ratingValue: "4.9",
      bestRating: "5",
      worstRating: "1",
    },
    review: {
      "@type": "Review",
      url: "https://www.dentalhousepinner.co.uk",
      datePublished: "2023-08-23",
      reviewBody:
        "As someone terrified of dentists, it's been a pleasure being a patient of Dental House. Dr Shah's been a godsend, a truly welcoming presence who never fails to put you at ease. Clearly explains each procedure and how it should feel. The whole team are great!",
      author: {
        "@type": "Person",
        name: "N R",
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5",
        worstRating: "1",
      },
      publisher: {
        "@type": "Organization",
        name: "Dental House Pinner",
        sameAs: "https://www.dentalhousepinner.co.uk",
      },
    },
  };

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Treatments",
        item: {
          url: `${siteUrl}/treatments`,
          id: `${siteUrl}/treatments`,
        },
      },
      {
        "@type": "ListItem",
        position: "3",
        name: "General Dentistry",
        item: {
          url: `${siteUrl}/treatments/general-dentistry`,
          id: `${siteUrl}/treatments/general-dentistry`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>
      {/* <LocalBusinessSchema /> */}
      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/treatments/general-dentistry`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.node.altText}`,
            },
          ],
        }}
      />

      <Layout>
        {generalDentistryIntroSection &&
          !checkPropertiesForNull(generalDentistryIntroSection, [
            "heading",
          ]) && (
            <TreatmentHero
              heading1={generalDentistryIntroSection?.title}
              smallHeadingTopText={generalDentistryIntroSection?.subtitle}
              img={
                generalDentistryIntroSection?.image.node?.localFile
                  .childImageSharp.gatsbyImageData
              }
              imgAlt={generalDentistryIntroSection?.image.node?.altText}
              heading2={generalDentistryIntroSection?.heading}
              headingSmall={generalDentistryIntroSection?.subHeading}
              text={
                <SafeHtmlParser
                  htmlContent={generalDentistryIntroSection?.description}
                />
              }
              btnLink={generalDentistryIntroSection?.cta.url}
              btnText={generalDentistryIntroSection?.cta?.title}
            />
          )}

        <ReviewSection
          heading={
            generalDentistryReviewSection?.heading ||
            "Reviews From Our Dentistry Patients"
          }
          smallheading={
            generalDentistryReviewSection?.subHeading ||
            "WHAT PEOPLE SAY ABOUT US"
          }
          page="General dentistry"
        />
        {generalDentistryTextWithCtaSection &&
          !checkPropertiesForNull(generalDentistryTextWithCtaSection, [
            "heading",
          ]) && (
            <section className="pt-5 pt-lg-7">
              <Container>
                <Row>
                  <Col className="text-center">
                    <p className="text-accent jost-bold fs-6">
                      {generalDentistryTextWithCtaSection?.subHeading}
                    </p>
                    <h2 className=" text-text-secondary  display-4 pb-4">
                      {generalDentistryTextWithCtaSection?.heading}
                    </h2>
                    <div className="text-start">
                      <SafeHtmlParser
                        htmlContent={
                          generalDentistryTextWithCtaSection?.description
                        }
                      />
                    </div>
                    {generalDentistryTextWithCtaSection.cta &&
                      generalDentistryTextWithCtaSection.cta?.url && (
                        <Button
                          className="text-white py-3 px-5 me-md-5 mt-lg-5 mt-4 w-100 w-md-auto"
                          variant="accent"
                          href={generalDentistryTextWithCtaSection.cta?.url}
                          target={
                            generalDentistryTextWithCtaSection.cta?.target ||
                            "_blank"
                          }
                          rel="noreferrer"
                        >
                          {generalDentistryTextWithCtaSection.cta?.title}
                        </Button>
                      )}

                    {generalDentistryTextWithCtaSection.cta2 &&
                      generalDentistryTextWithCtaSection.cta2?.url && (
                        <Button
                          as={Link}
                          to={generalDentistryTextWithCtaSection.cta2?.url}
                          target={
                            generalDentistryTextWithCtaSection.cta2?.target ||
                            "_blank"
                          }
                          variant="outline-accent"
                          className="btn  py-3 px-7 mt-4 mt-lg-5 w-100 w-md-auto"
                        >
                          {generalDentistryTextWithCtaSection.cta2?.title}
                        </Button>
                      )}
                  </Col>
                </Row>
              </Container>
            </section>
          )}
        {generalDentistryServiceSection &&
          !checkPropertiesForNull(generalDentistryServiceSection, [
            "heading",
          ]) && (
            <section className="pt-lg-8 pt-5 pb-6">
              <Container>
                <Row>
                  <Col className="text-center">
                    <p className="text-accent jost-bold fs-6">
                      {generalDentistryServiceSection?.subHeading}
                    </p>
                    <h2 className="text-text-secondary display-4 pb-4">
                      {generalDentistryServiceSection?.heading}
                    </h2>
                  </Col>
                </Row>
                {generalDentistryServiceSection.items &&
                  generalDentistryServiceSection.items.length > 0 && (
                    <Row className="pt-md-6 pt-5 g-5 h-100 justify-content-center">
                      {" "}
                      {generalDentistryServiceSection.items.map((cardItem) => (
                        <TreatmentCard
                          extraPadding
                          headingHeight="50px"
                          key={uuid4()}
                          image={
                            cardItem?.image.node?.localFile.childImageSharp
                              .gatsbyImageData
                          }
                          imgAlt={cardItem?.image.node?.altText}
                          title={cardItem?.heading}
                          text={
                            <SafeHtmlParser htmlContent={cardItem?.content} />
                          }
                          span={5}
                          price={cardItem?.price}
                          shorter="image-shorter"
                        />
                      ))}
                    </Row>
                  )}
                {generalDentistryServiceSection.cta &&
                  generalDentistryServiceSection.cta.url && (
                    <Row>
                      <Col className="text-center mt-md-6">
                        {" "}
                        <Button
                          className="text-white py-3 px-5 w-100 w-md-auto"
                          variant="accent"
                          href={generalDentistryServiceSection.cta?.url}
                          target={
                            generalDentistryServiceSection.cta?.target ??
                            "_blank"
                          }
                          rel="noreferrer"
                        >
                          {generalDentistryServiceSection.cta?.title}
                        </Button>
                      </Col>
                    </Row>
                  )}
              </Container>
            </section>
          )}

        {generalDentistryTwoColumnSection &&
          !checkPropertiesForNull(generalDentistryTwoColumnSection, [
            "heading",
          ]) && (
            <section className="py-5 py-lg-7">
              {" "}
              <PhotoLeft
                imgHeight="50rem"
                img={
                  generalDentistryTwoColumnSection?.image.node?.localFile
                    .childImageSharp.gatsbyImageData
                }
                imgAlt={generalDentistryTwoColumnSection?.image.node?.altText}
                text={
                  <div className="text-white">
                    <h2 className="text-center text-lg-start display-4 pb-4">
                      {generalDentistryTwoColumnSection?.heading}
                    </h2>
                    <GatsbyImage
                      className="mb-4 w-100 d-lg-none"
                      image={
                        generalDentistryTwoColumnSection?.image.node?.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={
                        generalDentistryTwoColumnSection?.image.node?.altText
                      }
                    />
                    <SafeHtmlParser
                      htmlContent={generalDentistryTwoColumnSection?.content}
                    />
                    {generalDentistryTwoColumnSection &&
                      generalDentistryTwoColumnSection.cta && (
                        <div className="text-center">
                          <Button
                            className="text-white mt-lg-4 py-3 px-5 w-100 w-md-auto"
                            variant="accent"
                            href={generalDentistryTwoColumnSection?.cta.url}
                            target={
                              generalDentistryTwoColumnSection.cta.target ??
                              "_blank"
                            }
                            rel="noreferrer"
                          >
                            {generalDentistryTwoColumnSection?.cta.title}
                          </Button>
                        </div>
                      )}
                  </div>
                }
                bgColour="bg-primary"
              />
            </section>
          )}

        {generalDentistrySmallTwoColumnSection &&
          !checkPropertiesForNull(generalDentistrySmallTwoColumnSection, [
            "heading",
          ]) && (
            <section className="pb-5 pb-lg-7">
              <Container>
                <Row className="g-5 gx-lg-8 align-items-center">
                  <Col lg={{ span: 5 }}>
                    <GatsbyImage
                      className="w-100"
                      image={
                        generalDentistrySmallTwoColumnSection.image?.node
                          ?.localFile.childImageSharp.gatsbyImageData
                      }
                      alt={
                        generalDentistrySmallTwoColumnSection.image?.node
                          .altText
                      }
                    />
                  </Col>{" "}
                  <Col lg={7}>
                    <h2 className=" display-4 pb-3">
                      {generalDentistrySmallTwoColumnSection?.heading}
                    </h2>
                    <SafeHtmlParser
                      htmlContent={
                        generalDentistrySmallTwoColumnSection?.content
                      }
                    />
                    {generalDentistrySmallTwoColumnSection &&
                      generalDentistrySmallTwoColumnSection.cta && (
                        <div className="text-center">
                          <Button
                            className="text-white mt-lg-4 py-3 px-5 w-100 w-md-auto"
                            variant="accent"
                            href={
                              generalDentistrySmallTwoColumnSection?.cta.url
                            }
                            target={
                              generalDentistrySmallTwoColumnSection.cta
                                .target ?? "_blank"
                            }
                            rel="noreferrer"
                          >
                            {generalDentistrySmallTwoColumnSection?.cta.title}
                          </Button>
                        </div>
                      )}
                  </Col>
                </Row>
              </Container>
            </section>
          )}
      </Layout>
    </>
  );
};

export default GeneralDenistryPage;
